body {
    font-family: "tahoma", sans-serif;
    background-image: url("./home1.jpg");
    background-size: cover;
}

.header-bg {
    height: 72px;
    position: absolute;
    left: 0;
    right: 0;
}

.header {
    display: flex;
    position: relative;
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, 0.5);
    font-family: "Open Sans", sans-serif;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    .logo {
        width: 200px;
        margin-left: 25px;
    }
    .navigation {
        margin-right: 50px;
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        right: 50px;
        align-self: center;
    }

    a {
        color: #312626;
        margin: 0 15px 0 0;
        padding: 2px 0px 3px 15px;
        border-left: 1px solid #5e5555;
        letter-spacing: 1px;
        text-decoration: none;
        &:hover {
            text-decoration: underline !important;
            font-weight: 600;
            color: #2e6e50 !important;
            text-underline-offset: 3px;
            cursor: pointer;
            text-decoration: underline;
        }

        &.active {
            text-decoration: underline !important;
            font-weight: 600;
            color: #2e6e50 !important;
            text-underline-offset: 3px;
            cursor: pointer;
            text-decoration: underline;
        }
        &:first-of-type {
            border-left: none;
        }
    }
}

.services {
    li {
        margin-bottom: 7px;
    }
}

.home {
    text-align: center;
    width: auto;
    min-height: 100vh;
    margin-top: 45px;
    font-family: "Open Sans", sans-serif;
    .logo {
        width: 165px;
    }
    h1 {
        color: black;
        background: white;
        display: initial;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12);
        padding: 5px 26px;
        line-height: 2;
        font-size: 24px;
        font-weight: 700;
    }

    h4 {
        font-size: 24px;
        font-weight: 700;
    }

    .home-grid {
        .text-justify {
            text-align: start;
            padding: 35px;
        }
        .inner-home-text {
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
                0px 1px 10px rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            padding: 20px;
            font-size: 14px;
            font-weight: 500;
            background: white;
            line-height: 24px;
        }
        background: #fefaf3;
        border-radius: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12);
        padding: 25px;
        .home-image {
            border-radius: 5px;
            box-shadow: 0px 2px 12px -1px rgba(0, 0, 0, 0.2), 0px 10px 17px rgba(0, 0, 0, 0.14),
                0px 1px 10px rgba(0, 0, 0, 0.12);
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    color: black;
    padding: 15px;
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, 0.5);
    font-family: "Open Sans", sans-serif;
    box-shadow: 5px 2px 4px 3px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    .footer-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    span {
        font-weight: 500;
        font-size: 16px;
    }

    .footer-links {
        a {
            color: #312626;
            margin: 0 15px 0 0;
            border-left: 1px solid #5e5555;
            letter-spacing: 1px;
            text-decoration: none;
            &:hover {
                text-decoration: underline !important;
                font-weight: 500;
                color: #2e6e50 !important;
                text-underline-offset: 3px;
                cursor: pointer;
                text-decoration: underline;
            }

            &.active {
                text-decoration: underline !important;
                font-weight: 500;
                color: #2e6e50 !important;
                text-underline-offset: 3px;
                cursor: pointer;
                text-decoration: underline;
            }
            &:first-of-type {
                border-left: none;
            }
        }
    }

    .footer-contancts {
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.contact-form {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    padding: 25px;
    background: white;
    margin-top: 25px;
    border-radius: 5px;
    h4 {
        color: #58b747;
        font-weight: 700;
    }
}

.gold-border {
    padding: 15px !important;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.contact-btn {
    color: white;
    padding: 10px 20px;
    background: #58b747;
    border: none;
    font-weight: 500;
    font-size: 20px;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    letter-spacing: 1px;
    &:hover {
        color: #58b747;
        background: white;
    }
}

.contacts-photo {
    margin-left: 25px !important;
}

.services-margin {
    padding: 15px;
}

@media only screen and (max-width: 767px) {
    .services-margin {
        padding: 0;
    }
    .header {
        .navigation {
            margin-right: 0;
            font-weight: 500;
            font-size: 14px;
            position: absolute;
            right: 0px;
            align-self: center;
        }

        .logo {
            width: 80px;
        }
    }

    .home-grid {
        .text-justify {
            padding: 0px !important;
        }
    }

    .contacts-photo {
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 1025px) {
    .glassy-text {
        max-width: 70%;
        padding: 1rem 2rem;
        margin: 25px;
    }
    .about {
        height: 100%;
    }
}
